@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Remove up and down arrow for input field */
/* For Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}



/* Hide the Scroll bar */
/* Hiding the scrollbar for Chrome, Safari, Edge */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hiding the scrollbar for Firefox */
.hide-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

/* Make sure the content is still scrollable */
.hide-scrollbar {
  overflow: -moz-scrollbars-none;
  /* Older Firefox */
}


.ql-font-arial {
  font-family: 'Arial', sans-serif;
}
