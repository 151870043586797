.froala-editor-container h1 {
    font-size: 2.5em !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
}

.froala-editor-container h2 {
    font-size: 2em !important;
    font-weight: bold !important;
    margin-bottom: 8px !important;
}

.froala-editor-container h3 {
    font-size: 1.75em !important; 
    font-weight: bold !important; 
    margin-bottom: 6px !important; 
}

.froala-editor-container h4 {
    font-size: 1.5em !important;
    font-weight: bold !important;
    margin-bottom: 4px !important;
}

.froala-editor-container h5 {
    font-size: 1.25em !important;
    font-weight: bold !important;
    margin-bottom: 3px !important;
}

.froala-editor-container h6 {
    font-size: 1em !important;
    font-weight: bold !important;
    margin-bottom: 2px !important;
}

.froala-editor-container a {
    color: blue !important;
    text-decoration: underline !important;
}